export const getNumberOfWorkingDays = () => {
    const workingDays = [1, 2, 3, 4, 5]
    var numberOrWorkingDays = 0;

    const now = new Date();
    const currentMonth = now.getMonth();
    const currentYear = now.getFullYear()
    const lastDay = new Date(currentYear, currentMonth + 1, 0);

    const daysInMonth = lastDay.getDate();

    for (var i = 1; i < daysInMonth; i++) {
        var day = new Date(currentYear, currentMonth, i)
        if (workingDays.includes(day.getDay())) {
            numberOrWorkingDays = numberOrWorkingDays + 1;
        }
    }
    return numberOrWorkingDays;
}
