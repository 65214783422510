const salarayKey = "esatto-b2b-salary"
const benefitPrefix = "esatto-b2b-benfit-"

export const getSalary = () => {
    const value = localStorage.getItem(salarayKey);
    return value ? value : 100;
}

export const setSalary = (value) => {
    localStorage.setItem(salarayKey, value);
}

export const getBenefits = (benefits) => {
    for (const property in benefits) {
        const cachedBenefitStatus = localStorage.getItem(`${benefitPrefix}${property}`)
        if (cachedBenefitStatus) {
            benefits[property].isSelected = JSON.parse(cachedBenefitStatus)
        }
    }
    return benefits;
}

export const setBenefit = (name, value) => {
    localStorage.setItem(`${benefitPrefix}${name}`, value)
}