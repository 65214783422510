const NumberInput = ({ labelText, inputName, value, onChangeHandler, placeholder, children }) => {

    return (
        <div className="col">
            <div className="mb-3">
                <label className="form-label">{labelText}</label>
                <input className="form-control" type="number" name={inputName} value={value} onChange={onChangeHandler} placeholder={placeholder} ></input>
                {children}
            </div>
        </div>
    )
}

export default NumberInput