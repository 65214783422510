import { currencyFormat } from '../utils/currency.service'
const InvoiceSummary = ({ basicWork, support, benefits, additionalCosts }) => {

    const getBenefitsTotalPrice = () => {
        var totalPrice = 0;
        for (const property in benefits) {
            if (benefits[property].isSelected) {
                totalPrice += benefits[property].employer
            }
        }

        return totalPrice;
    }

    const calculateBasicWork = () => {
        return basicWork.salary * basicWork.workingHours
    }

    const calculateSupport = () => {
        return support.supportPrice * support.supportHours
    }

    const benefitsTotalPrice = getBenefitsTotalPrice()


    const calculateFinalPrice = () => {
        var totalPrice = 0;
        totalPrice += benefitsTotalPrice;
        totalPrice += calculateBasicWork();
        totalPrice += calculateSupport();
        totalPrice += parseFloat(additionalCosts);

        return totalPrice.toFixed(2);
    }


    //Dynamic sections
    var supportSection;
    var additionalCostsSection;

    if (support.isActive) {
        supportSection = <tr>
            <td>Usługa wsparcia: on call (nazwa projektu)</td>
            <td>{currencyFormat(calculateSupport())}</td>
        </tr>
    }


    if (additionalCosts > 0) {
        additionalCostsSection = <tr>
            <td>Koszty dodatkowe</td>
            <td>{currencyFormat(additionalCosts)}</td>
        </tr>
    }



    return (
        <div className="card mb-4">
            <div className="card-header">
                Podsumowanie faktury
            </div>
            <div className="card-body">
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th>Nazwa</th>
                            <th>Cena</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Usługi programistyczne</td>
                            <td>{currencyFormat(calculateBasicWork())}</td>
                        </tr>
                        {supportSection}
                        <tr>
                            <td>Ustalone usługi dodatkowe</td>
                            <td>{currencyFormat(benefitsTotalPrice)}</td>
                        </tr>
                        {additionalCostsSection}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td className="table-success">Kwota netto całkowita</td>
                            <td className="table-success">
                                <span className="font-weight-bold">
                                    {currencyFormat(calculateFinalPrice())}
                                </span>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>


    )
}

export default InvoiceSummary