import BenefitItem from "./benefit-item.component";
import NumberInput from "./number-input.component";

const Benefits = ({ benefits, switchHandler, additionalCosts, additionalCostsHandler }) => {
    return (
        <div className="card mb-4">
            <div className="card-header">
                Kalkulator ustalonych usług dodatkowych
            </div>
            <div className="card-body">
                <BenefitItem fieldName="luxmed" item={benefits.luxmed} onChangeHandler={switchHandler}></BenefitItem>
                <BenefitItem fieldName="multiSportLight" item={benefits.multiSportLight} onChangeHandler={switchHandler}></BenefitItem>
                <BenefitItem fieldName="multiSportClassic" item={benefits.multiSportClassic} onChangeHandler={switchHandler}></BenefitItem>
                <BenefitItem fieldName="multiSportPlus" item={benefits.multiSportPlus} onChangeHandler={switchHandler}></BenefitItem>
                <BenefitItem fieldName="pzu" item={benefits.pzu} onChangeHandler={switchHandler}></BenefitItem>
                <hr />

                <NumberInput labelText="Dodatkowe koszty" inputName="" value={additionalCosts} onChangeHandler={additionalCostsHandler} placeholder="Kwota netto zagodzinę" >
                    <small>Przykadowo kilometrówka albo bilety za komunikację zbiorową</small>
                </NumberInput>
            </div>
        </div>
    )
}

export default Benefits