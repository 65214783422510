import { useState } from 'react';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Invoice from "./components/invoice.component";
import Benefits from "./components/benefit.component";
import InvoiceSummary from './components/invoice-summary.component'
import { getNumberOfWorkingDays } from './utils/date.service'
import costs from './costs.json';
import { getSalary, getBenefits, setBenefit } from './utils/localstorage.service'



function App() {
  const basicWorkInit = {
    salary: getSalary(),
    workingHours: (getNumberOfWorkingDays() * 8),
  }

  const supportInit = {
    supportPrice: 0,
    supportHours: 0,
    isActive: false
  }

  const [basicWork, setBasicWork] = useState(basicWorkInit)
  const [support, setSupport] = useState(supportInit)
  const [benefits, setBeneftis] = useState(getBenefits(costs));
  const [additionalCosts, setAdditionalCosts] = useState(0)

  const additionalCostsHandler = (event) => {
    setAdditionalCosts(event.target.value)
  }

  const benefitsSwitchHandler = (event) => {
    const { name, checked } = event.target;

    setBeneftis({
      ...benefits, [name]: {
        ...benefits[name],
        isSelected: checked
      }
    });

    setBenefit(name, checked)
  }

  const handleChangeIsActiveInSupport = (event) => {
    const { name, checked } = event.target;

    setSupport({
      ...support, [name]: checked
    });
  }

  const handleChangeAtBasicWork = (event) => {
    const { name, value } = event.target;
    setBasicWork({ ...basicWork, [name]: value });
  };


  const handleChangeAtSupport = (event) => {
    const { name, value } = event.target;
    setSupport({ ...support, [name]: value });
  };


  return (
    <div className="App container">
      <h2 className="pb-4">Esatto - B2B</h2>
      <Invoice basicWork={basicWork} support={support} supportHandler={handleChangeAtSupport} basicWorkHandler={handleChangeAtBasicWork} switchHandler={handleChangeIsActiveInSupport} />
      <Benefits benefits={benefits} switchHandler={benefitsSwitchHandler} additionalCosts={additionalCosts} additionalCostsHandler={additionalCostsHandler} />
      <InvoiceSummary basicWork={basicWork} support={support} benefits={benefits} additionalCosts={additionalCosts} />
    </div>
  );
}

export default App;
