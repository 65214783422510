import { currencyFormat } from '../utils/currency.service'

const BenefitItem = ({ fieldName, item, onChangeHandler }) => {

    return (
        <div className="row">
            <div className="col">
                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" checked={item.isSelected} name={fieldName} onChange={onChangeHandler} />
                    <label className="form-check-label">{item.name}</label>
                </div>
            </div>
            <div className="col">
                Koszt na fakturze: {currencyFormat(item.employer)} 
            </div>
        </div>
    )
}

export default BenefitItem