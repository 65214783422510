import NumberInput from './number-input.component'
import {setSalary} from '../utils/localstorage.service'

const Invoice = ({ basicWork, support, supportHandler, basicWorkHandler, switchHandler }) => {

    var supportContainer;
    if (support.isActive) {
        supportContainer = <div className="support-container">
            <div className="row">
                <NumberInput labelText="Stawka za  godzinę netto" inputName="supportPrice" value={support.supportPrice} onChangeHandler={supportHandler} placeholder="Kwota netto zagodzinę" />
                <NumberInput labelText="Ilość przepracowanych godzin" inputName="supportHours" value={support.supportHours} onChangeHandler={supportHandler} placeholder="Ilość przepracowanych godzin" />
            </div>
        </div>
    }

    return (
        <div className="card mb-4">
            <div className="card-header">
                Kwota na fakturze za usługi programistyczne
            </div>
            <div className="card-body">
                <div className="row">
                    <NumberInput labelText="Stawka za  godzinę netto" inputName="salary" value={basicWork.salary} onChangeHandler={(event) =>{basicWorkHandler(event);setSalary(event.target.value) }} placeholder="Kwota netto zagodzinę" />
                    <NumberInput labelText="Ilość przepracowanych godzin" inputName="workingHours" value={basicWork.workingHours} onChangeHandler={basicWorkHandler} placeholder="Ilość przepracowanych godzin" >
                        <small>Zarejestrowaną liczbę godzin możesz pobrać stąd <a href="https://www.tictacmobile.com/LoggedIn.jsp">Klik!</a></small>
                    </NumberInput>

                </div>

                <div className="form-check form-switch">
                    <input className="form-check-input" type="checkbox" role="switch" checked={support.isActive} id="SupportSwitch" name="isActive" onChange={switchHandler} />
                    <label className="form-check-label" htmlFor="SupportSwitch">Czy udzielałeś wsparcia (on call / stand by) dla klienta?</label>
                </div>
                {supportContainer}
            </div>
        </div>
    )
}

export default Invoice